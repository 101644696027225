<template>
  <div class="bac">
    <div>
      <el-card shadow="always" style="width: 1500px">
        <el-button type="primary" @click="this.addCerDialog = true">添加项目</el-button>
        <el-table
            :data="info"
            highlight-current-row
            max-height="900"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="图片">
            <template #default="scope">
              <el-image :src="scope.row.pic" style="width: 156px;"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="证书名称" prop="name"></el-table-column>
          <el-table-column label="证书编号" prop="bankroll"></el-table-column>
          <el-table-column label="操作" width="500">
            <template #default="scope">
              <el-button type="danger" @click="Delete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>

  <el-dialog v-model="addCerDialog" title="添加证书" width="50%">
    <el-form :model="addCerForm" style="width: 50%;">
      <el-form-item label="证书名称" label-width="100px">
        <el-upload ref="upload"
                   action="#"
                   accept="image/png,image/gif,image/jpg,image/jpeg"
                   list-type="picture-card"
                   :auto-upload="false"
                   :on-change="imgChange"
                    v-if="addCerForm.pic === ''">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-image :src="addCerForm.pic" width="156px" v-if="addCerForm.pic !== '' "/>
      </el-form-item>

      <el-form-item label="证书名称" label-width="100px">
        <el-input v-model="addCerForm.name" autocomplete="off" />
      </el-form-item>

      <el-form-item label="证书编号" label-width="100px">
        <el-input v-model="addCerForm.number" autocomplete="off" />
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addCerDialog = false">取消</el-button>
        <el-button type="primary" @click="AddCer">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "AllCertificate",
  data(){
    return{
      subform:{
        sid:'',
        currentPage:1,
        pageSize: 20,
      },

      info:[''],
      total: 0,

      addCerDialog: false,

      deleteFrom:{
        cid:'',
      },

      addCerForm:{
        name:'',
        number:'',
        pic:'',
        sid:'',
      },
    }
  },
  mounted() {
    this.subform.sid = parseInt(this.$route.query.sid);
    this.addCerForm.sid = parseInt(this.$route.query.sid);
    this.getInf()
  },
  methods:{
    getInf() {
      this.$http({
        method: 'post',
        url: '/supplyChain/getAllCertificatesForSupplier',
        data: this.subform,
      }).then(res => {
        this.info = res.data.data;
        this.total = res.data.count;
        this.subform.currentPage = res.data.currentPage;
      })
    },

    Delete(index){
      this.deleteFrom.cid = index;
      this.$http({
        method: 'post',
        url: '/admin/deleteCertificate',
        data: this.deleteFrom,
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '删除成功',
            message: res.data.message,
            type: 'success'
          });
          this.getInf();
        }
      })
    },

    imgChange (files, fileList) {
      if (!(fileList[0].raw.type === 'image/png' || fileList[0].raw.type === 'image/gif' || fileList[0].raw.type === 'image/jpg' || fileList[0].raw.type === 'image/jpeg')) {
        this.$notify.warning({
          title: '警告',
          message: '请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片'
        })
      }
      let size = fileList[0].raw.size / 1024 / 1024 / 2
      if (size > 2) {
        this.$notify.warning({
          title: '警告',
          message: '图片大小必须小于2M'
        })
        return;
      }
      let formData = new FormData;
      formData.append('file',fileList[0].raw);
      formData.append('sid', this.addCerForm.sid);

      this.$http({
        method: 'post',
        url: '/admin/uploadCertificatePic',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        this.addCerForm.pic = res.data;
      })
    },

    AddCer(){
      this.$http({
        method: 'post',
        url: '/admin/addCertificate',
        data: this.addCerForm,
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '添加成功',
            message: res.data.message,
            type: 'success'
          });
          this.addCerDialog = false;
          this.getInf();
        }
      })
    }
  }
}
</script>

<style scoped>
.bac{
  height: 1194px;
}
</style>